// --------------------
// Styles
// --------------------
import 'virtual:windi.css'; // 先做引用，避免預檢樣式蓋掉自訂樣式
import '/css/app.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';

// --------------------
// Components
// --------------------
import '../components/heading-1.js';

// --------------------
// Vendor
// --------------------
// import { createApp } from 'vue';
// import { defineCustomElement } from 'vue';

import AOS from 'aos';
import 'aos/dist/aos.css';

setTimeout(() => {
  AOS.init();
}, 180);

// --------------------
// Vue custom elements (無法吃全域 css，待處理)
// --------------------
// import Heading1 from './components/Heading1.ce.vue';
// const Heading1Element = defineCustomElement(Heading1);
// customElements.define('heading-1', Heading1Element);

// --------------------
// Vue App
// --------------------
// const app = createApp(App).mount('#app');

// --------------------
// Contact Form Submit
// --------------------
import './contact-form.js';
