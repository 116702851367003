// 頁面載入後進行
window.addEventListener('load', () => {
    handleContactFormSubmit();
});

function handleContactFormSubmit() {
  let contactFormEl = document.getElementById('contact-form');

  if (! contactFormEl) {
    return;
  }

  contactFormEl.addEventListener('submit', (e) => {
    e.preventDefault();

    let btn = e.target.querySelector('button[type=submit]');
    let btnText = btn.innerText;
    btn.innerText = '傳送中 ..';
    btn.setAttribute('disabled', true);

    // reCAPTCHA
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdrBVsqAAAAAP1gGEfVYN5xNa_SPpxTHekNpEy9', { action: 'submit' }).then(function(token) {
        // Add recaptcha response
        contactFormEl.querySelector('input[name="recaptcha-response"]').value = token;

        // Form data
        let data = new FormData(contactFormEl);

        fetch('/mail.php', {
          method: 'POST',
          body: data
        })
          .then((response) => {
            console.log(response);

            if (! response.ok) {
              return Promise.reject(response.status);
            }

            return Promise.resolve(response);
          })
          .then((response) => {
            // Pixel
            fbq('track', 'Contact');

            btn.innerText = '訊息已送出';
            alert('訊息已送出');
          })
          .catch((status) => {
            let message = status === 500 ? '系統發生錯誤' : '欄位填寫不全或格式錯誤，請重新輸入';

            btn.removeAttribute('disabled');
            btn.innerText = btnText;
            alert(message);
          });
      });
    });
  });
}
