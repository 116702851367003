import { MinzeElement } from 'minze';

(class MyElement extends MinzeElement {
  html = () => `
    <section part="wrapper">
      <h1 part="title">
        <slot></slot>
      </h1>

      <h2 part="sub-title">
        <slot name="sub-title"></slot>
      </h2>

      <div part="line"></div>
    </section>
  `
}).define('heading-1');

//<!-- <h2 class="noto noto-300 text-2xl text-gray-600 text-center">最佳化的精準行銷方式，自有廣告監控優化管理系統</h2> -->
